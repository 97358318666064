import useConfetti from './hooks/useConfetti'
import './styles/main.css'
import gsap from 'gsap'

let windowHeight = window.innerHeight
let windowWidth = window.innerWidth
const {startConfetti} = useConfetti();
const notClickableElement = document.querySelector("button[data-not-clickable=true]");
const clickableElement = document.querySelector("button[data-not-clickable=false]");
const text = document.querySelector("h1");

const calculateNewPosition = (actualPosition) => {
    const offsetX = windowWidth - actualPosition.width;
    const offsetY = windowHeight - actualPosition.height;
    
    const newXPosition = Math.floor(Math.random() * offsetX);
    const newYPosition = Math.floor(Math.random() * offsetY);

    return {
        newXPosition,
        newYPosition
    }
}

const moveOutOfView = () => {
    if (!notClickableElement) {
        return undefined;
    }
    const actualPosition = notClickableElement.getBoundingClientRect()
    const {newXPosition, newYPosition} = calculateNewPosition(actualPosition)
    gsap.to(notClickableElement, {
        duration: 0.5,
        left: newXPosition,
        top: newYPosition,
        position: "absolute"
    })
}

const setNotClickableButton = () => {   
    return new Promise((resolve) => {
        const {left: dummyButtonLeft, top: dummyButtonTop} = document.querySelector("button[data-dummy-identifier").getBoundingClientRect();
        notClickableElement.style.left = `${dummyButtonLeft}px`;
        notClickableElement.style.top = `${dummyButtonTop}px`;
        resolve()
    })
}

notClickableElement.addEventListener("touchstart", moveOutOfView);
notClickableElement.addEventListener("mouseover", moveOutOfView);
notClickableElement.addEventListener("click", () => {
    text.innerHTML = "Please, no biting involved, I promise! <span>🥺👉👈</span>"
})

clickableElement.addEventListener("click", () => {
    document.querySelectorAll("button.btn.btn-secondary").forEach((element) => {
        element.remove()
    })
    startConfetti();
    text.innerHTML = "I had a feeling you'd say yes. I mean, who could resist my charm, right? Let's make it legendary together! <span>🫶🏾</span>"
})

window.addEventListener("load", () => {
    const setNotClickablePosition = setNotClickableButton();

    setNotClickablePosition.then(() => {
        document.querySelector(".loader-container").remove()
        document.querySelector("main").style.visibility = "visible";
    })
})

window.addEventListener("resize", () => {
    windowHeight = window.innerHeight
    windowWidth = window.innerWidth
    setNotClickableButton();
})