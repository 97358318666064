import JSConfetti from "js-confetti";

export default function useConfettiJs() {
    const jsConfetti = new JSConfetti();
    
    const startConfetti = () => {
        jsConfetti.addConfetti({
            confettiRadius: 7,
        })

        jsConfetti.addConfetti({
            emojis: ["❤️"],
            emojiSize: 40,
            confettiNumber: 25
        })
    }

    

    return {startConfetti}
}